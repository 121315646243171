const mousonturmContent = {
    german: {
        arrow: "<< ",
        headline: "KOOPERATIONEN",
        secondHeadline: "Künstlerhaus Mousonturm",
        first: "Nocturnal Unrest wäre nicht ohne das Künstlerhaus Mousonturm möglich. Das gesamte Festival ist in enger Kooperation mit dem Künstlerhaus entstanden, das dem nOu-Kollektiv, neben der Bereitstellung der Spielstätte, dramaturgisch und technisch beratend zur Seite steht.",
        second: "Das Künstlerhaus Mousonturm wurde 1988 als eines der ersten Freien Produktionshäuser in Deutschland eröffnet und zählt heute international zu den wichtigsten und erfolgreichsten freien Produktionszentren. Als „Haus der Künstler:innen“ stellt der Mousonturm den wiederkehrenden Austausch mit Kunstschaffenden, Gruppen und Kollektiven der zeitgenössischen freien Szene im deutschsprachigen, europäischen und auch außereuropäischen Raum in den Mittelpunkt seiner Programm- und Produktionsarbeit. Auch die Stadt und die Metropolregion wird hierbei als Bühne sowie als Interventions- und Aktionsraum begriffen, der regelmäßig bespielt und gemeinsam mit den Bürger:innen der Stadt erkundet wird.",
        third: "Das Künstlerhaus Mousonturm will Raum sein für alle, unabhängig von Herkunft, Geschlecht, sexueller Orientierung, Nationalität, Be_hinderung, Religion, Kultur, Aufenthaltsstatus, Alter, Klasse, Aussehen oder weiterer Zugehörigkeiten und Zuschreibungen. Diskriminierungssensible Räume zu eröffnen begreift das Haus als seine gesellschaftliche Aufgabe und Verpflichtung. Hier der Link zur ",
        thirdLink: "Selbstverpflichtung zu Antidiskrimierung des Mousonturms",
        fourth: "Hier geht’s zur Website vom ",
        fourthLink: "Künstlerhaus Mousonturm."
    },
    english:  {
        arrow: "<< ",
        headline: "COOPERATIONS",
        secondHeadline: "Künstlerhaus Mousonturm",
        first: "Nocturnal Unrest would not be happening without Künstlerhaus Mousonturm. The entire festival was created in close cooperation with the Künstlerhaus, which, in addition to providing the venue, assists the nOu-collective with dramaturgical and technical advice.",
        second: "The Künstlerhaus Mousonturm was opened in 1988 as one of the first independent production centres in Germany. Today it is one of the most important and successful independent production centres internationally. As a \"House of Artists\", the Mousonturm places the recurring exchange with artists, groups and collectives of the contemporary independent art scene in the German-speaking, European and also non-European regions at the centre of its programming and production work. Künstlerhaus Mousonturm takes Frankfurt/M. as well as the surrounding metropolitan region as a stage which is regularly used for artistic interventions and actions and which should be together with the citizens of the city explored.",
        third: "The Künstlerhaus Mousonturm tries to create spaces that are free for everyone, regardless of origin, gender, sexual orientation, nationality, disability, religion, culture, residence status, age, class, appearance or other affiliations and attributions. Here is the link to the ",
        thirdLink: "commitment to anti-discrimination",
        thirdSecond: " the Künstlerhaus signed.",
        fourth: "Click here for the website of ",
        fourthLink: "Künstlerhaus Mousonturm."
    }
}

export {mousonturmContent}