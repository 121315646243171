import React, { useContext } from "react"
import { Link } from "gatsby"
import Template from "../../components/template"
import "../../styles/kooperationspartner.css"
import { mousonturmContent } from "../../content/kooperationen/mousonturm"
import { SettingsContext } from "../../contexts/settings"

const MousonturmPage = () => {
    const [settings, ] = useContext(SettingsContext)
    const content = mousonturmContent[settings.language]
    return (
        <Template currentPage="cooperations">
            <section>
            <div>
                <h1>
                    <Link to="/kooperationen" class="back-arrow">{content.arrow}{content.headline}</Link>
                </h1>
                <h2>{content.secondHeadline}</h2>

                <div className="pic-text-mousonturm">
                    <div className="pic-mousonturm">
                        <img className="logo-mousonturm" src={settings.isHighContrast ? "/logos/Kooperationspartner/bw/inverted/bw_inverted_Logo_Mousonturm.png" : "/logos/Kooperationspartner/bw/bw_Logo_Mousonturm.png"} alt="Logo vom Künstlerhaus Mousonturm"/>
                    </div>
                    <div className="text-mousonturm">
                        <p>
                            {content.first}
                        </p>
                        <p>
                            {content.second}
                        </p>
                        <p>
                            {content.third}
                            <a className="link" href="https://www.mousonturm.de/wp-content/uploads/2020/03/200303_BIP-Selbstverpflichtung.pdf" target="_blank" rel="noopener noreferrer">{content.thirdLink}</a>
                            {content.thirdSecond}
                        </p>
                        <p className="end-of-site">
                            {content.fourth}
                            <a className="link" href="https://www.mousonturm.de" target="_blank" rel="noopener noreferrer">
                                {content.fourthLink}
                                .
                            </a>
                        </p>
                    </div>
                </div>
                
            </div>    
            </section>
        </Template >
    )
}

export default MousonturmPage